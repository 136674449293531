import React from 'react';
import { withNamespaces } from 'react-i18next';
const NoService = () => {
  return <div className="" style={{"height": "100vh", "fontSize": "40px"}}>
  <div className='d-flex justify-content-center align-items-center text-light h-100 fs-20'>
    <p>NO SERVICE AVAILABLE</p>
  </div>
  </div>;
};

export default withNamespaces()(NoService); 