import React, { useEffect, useRef } from "react";
import { Provider } from "react-redux";
//import store
import store from "./store/index";
// import router
import Router from "./Router";
//import i18n
import "./plugins/i18n";
//import compoents
// import Header from "./components/partials/Header";
import ScrollArrows from "./components/partials/ScrollArrows";
//CSS Imports
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-modal-video/css/modal-video.min.css";
import "react-day-picker/lib/style.css";
import "./assets/css/mycinema.css";
import "./assets/css/App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchGlobalSettings } from "./store/global/globalActions";
import { useDispatch, useSelector } from "react-redux";
import { persistor } from "./store/index";
import { PersistGate } from "redux-persist/integration/react";

const App = () => {
  const appRef = useRef();

  useEffect(() => {
    console.log = function() {};
    console.warn = function() {};
    console.error = function() {};
  }, [])

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className="App" ref={appRef}>
          <Router />
        </div>
        {/* <ScrollArrows/> */}
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
          limit={1}
        />
      </PersistGate>
    </Provider>
  );
};

export default App;
